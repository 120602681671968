export const indianFormat = (x: any) => {
  if (x) {
    x = x?.toString();
    var afterPoint = "";
    if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    return `${otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}${lastThree}${
      afterPoint?.length ? afterPoint.slice(0, 3) : ""
    }`;
  } else {
    return "-";
  }
};
