"use client";

import React, { forwardRef, useRef } from "react";
import {
  LandPlot,
  Tractor,
  BellDot,
  CloudCog,
  User,
  Sprout,
  Droplets,
} from "lucide-react";

import { cn } from "@/lib/utils";
import { AnimatedBeam } from "@/components/mgui/AnimatedBeams";

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-12 items-center justify-center rounded-full border-2 border-border bg-white p-1 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export function Beamers({ className }: { className?: string }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);
  const div7Ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={cn(
        "relative flex w-full items-center justify-center rounded-lg border bg-background p-10",
        className
      )}
      ref={containerRef}
    >
      <div className="flex size-full flex-row items-stretch justify-between gap-10 max-w-lg">
        <div className="flex flex-col justify-center gap-4">
          <Circle ref={div1Ref}>
            <Tractor size={35} className="text-indigo-300" />
          </Circle>
          <Circle ref={div2Ref}>
            <Droplets size={35} className="text-blue-500" />
          </Circle>
          <Circle ref={div3Ref}>
            <Sprout size={35} className="text-green-700" />
          </Circle>
          <Circle ref={div4Ref}>
            <LandPlot size={35} className="text-yellow-800" />
          </Circle>
          {/* <Circle ref={div4Ref}>
            <Files />
          </Circle>
          <Circle ref={div5Ref}>
            <Icons.notion />
          </Circle> */}
        </div>
        <div className="flex flex-col justify-center items-center pl-5">
          <Circle ref={div6Ref} className="bg-blue-700">
            <CloudCog size={35} className="text-white" />
          </Circle>
        </div>
        <div className="flex flex-col justify-center">
          <Circle ref={div7Ref}>
            <User size={35} />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div6Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div6Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div6Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div6Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div7Ref}
      />
      {/* <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div6Ref}
      /> */}
      {/* <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div6Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div7Ref}
      /> */}
    </div>
  );
}
