"use client";

import React from "react";
// import Meteors from "./ui/Meteors";
// import { TextGenerate } from "./ui/TextGenerate";
// import { Beams } from "./ui/Beams";
// import LinkText from "./LinkText";
// import { BorderBeam } from "./mgui/BorderBeams";
// import { MagicCard, MagicContainer } from "@/components/mgui/Mcards";
import { AnimatedList } from "@/components/mgui/AnimatedList";
import { cn } from "@/lib/utils";

interface Item {
  name: string;
  description: string;
  icon: string;
  color: string;
  time: string;
}

let notifications = [
  {
    name: "Solutions Dashboard",
    description:
      "Track investor funds, farmland performance,and yields with real-time updates",
    time: "Live",
    icon: "💼",
    color: "#FFB800",
  },
  {
    name: "Comprehensive Live Reports",
    description:
      "Investors get live analytics with detailed reports on their farm's performance.",
    time: "Organized",
    icon: "📊",
    color: "#1E86FF",
  },
  {
    name: "Farming Solutions",
    description: "We put knowledge to work",
    time: "Optimized",
    icon: "🚜",
    color: "#00C9A7",
  },
  // {
  //   name: "Transparency",
  //   description:
  //     "Investors can see the status of their investments at any time",
  //   time: "verify",
  //   icon: "👁️",
  //   color: "#FF3D71",
  // },
];

notifications = Array.from({ length: 10 }, () => notifications).flat();

const Notification = ({ name, description, icon, color, time }: Item) => {
  return (
    <figure
      className={cn(
        "relative mx-auto min-h-fit w-full cursor-pointer overflow-hidden rounded-2xl p-4",
        // animation styles
        "transition-all duration-200 ease-in-out hover:scale-[103%]",
        // light styles
        "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
        // dark styles
        "transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]"
      )}
    >
      <div className="flex flex-row items-center gap-3">
        <div
          className="flex size-10 items-center justify-center rounded-2xl"
          style={{
            backgroundColor: color,
          }}
        >
          <span className="text-lg">{icon}</span>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center whitespace-pre text-lg font-medium dark:text-white ">
            <span className="text-sm sm:text-lg">{name}</span>
            <span className="mx-1">·</span>
            <span className="text-xs text-gray-500">{time}</span>
          </figcaption>
          <p className="text-sm font-normal dark:text-white/60">
            {description}
          </p>
        </div>
      </div>
    </figure>
  );
};

export function Hero({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "relative flex h-[500px] w-full flex-col py-6 px-2 overflow-hidden rounded-lg bg-background md:shadow-xl",
        className
      )}
    >
      <AnimatedList>
        {notifications.map((item, idx) => (
          <Notification {...item} key={idx} />
        ))}
      </AnimatedList>
    </div>
  );
}

// function Hero() {
//   return (
//     <div>

//     </div>
//   );
// }

// export default Hero;

{
  /* <div className="flex flex-col relative justify-center items-center mt-4">
        <div className="z-10 text-2xl top-[30%] md:text-6xl lg:text-8xl absolute md:top-[40%] p-10">
          <TextGenerate words={`Galaxy of investments`} />
        </div>
        <div className="z-10 absolute text-2xl top-[60%] md:text-6xl lg:text-8xl md:top-[65%] text-color-brand">
          <TextGenerate words={`Universe of wealth`} />
        </div>
        <div className="circle-wrap">
          <div className="ic1-w-h  circle-style"></div>
        </div>

        <div className="circle2-wrap">
          <div className="ic2-w-h circle2-style"></div>
        </div>

        <div className="circle3-wrap">
          <div className="ic3-w-h circle-style "></div>
        </div>
        <Beams />
      </div> */
}
{
  /* <div className="p-1 md:p-20 sm:mt-20 flex flex-col justify-center items-center border-[1px] border-[#e3e2ff11] layer-2-bg relative m-3 rounded-md"> */
}
{
  /* <div className="mt-20 flex flex-col text-center items-center justify-center p-1 text-2xl md:text-5xl">
          <LinkText />
          <span>your perfect investment match on our platform</span>
        </div> */
}
{
  /* <div className="info-scroll mt-4"> */
}
{
  /* <MagicContainer className={"mt-2"}>
          <MagicCard className=" shadow-2xl">
            <div className="text-color-brand2 flex justify-center text-2xl md:text-3xl text-center p-2 md:p-7">
              Exhausted from endless sign-ups and the relentless search for the
              ideal investment online?
            </div>
            <div className="text-[#e2e8ffc6] flex justify-center text-xl text-center p-5 md:px-10">
              Our platform aggregates investment options from across the web,
              delivering a critical analysis of their pros and cons after
              comprehensive research. This means no more creating accounts on
              every platform or searching endlessly for the right investment. We
              save you time and equip you with the knowledge to make informed
              decisions.
            </div>
            <BorderBeam size={250} duration={12} delay={9} />
          </MagicCard>
        </MagicContainer> */
}
{
  /* </div> */
}
